<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSaveForm()"
    @cancel="onCancel('TelemarketingTasks')"
  >
    <template #scriptText="{ field }">
      <p class="editable-form__description">{{ field.label }}</p>
      <sm-textarea
        v-model="currentScriptText"
        rows="10"
        resize="vertical"
        disabled
      />
    </template>

    <template #contacts>
      <div
        v-for="(item, index) in form.contacts"
        :key="`contact-${index}`"
        class="telemarketing-form__row"
      >
        <div class="telemarketing-form__wrapper">
          <div class="telemarketing-form__column">
            <p class="telemarketing-form__description">ФИО контактного лица</p>
            <sm-input v-model="form.contacts[index].fio" type="text" />
          </div>

          <div class="telemarketing-form__column">
            <p class="telemarketing-form__description">
              Телефон контактного лица
            </p>
            <sm-input v-model="form.contacts[index].phone" type="text" />
          </div>

          <div class="telemarketing-form__column">
            <p class="telemarketing-form__description">
              Email контактного лица
            </p>
            <sm-input v-model="form.contacts[index].mail" type="text" />
          </div>

          <div class="telemarketing-form__column">
            <p class="telemarketing-form__description">
              Должность контактного лица
            </p>
            <sm-input v-model="form.contacts[index].position" type="text" />
          </div>
        </div>

        <button
          v-show="form.contacts.length"
          class="telemarketing-form__delete-button"
          @click="openDeleteContactModal(index)"
        >
          <sm-icon name="trash" />
        </button>
      </div>

      <button class="telemarketing-form__add-button" @click="onAddContact">
        <sm-icon name="plus" />
        Добавить контакт
      </button>
    </template>

    <template #tab-1>
      <div class="editable-item__tab-content">
        <sm-datatable
          :caption="fields[1].table.caption"
          :headers="fields[1].table.headers"
          :items="fields[1].table.items"
        >
          <template #top-panel>
            <button
              v-if="notCreatedTelemarketingScriptQuestions.length"
              class="editable-list__top-panel-button editable-list__top-panel-button--create"
              @click="openModal(fields[1].table.key)"
            >
              <sm-icon name="plus" />
              Добавить
            </button>
          </template>
          <template #colgroup>
            <col
              width="auto"
              :span="
                fields[1].table.showEditButton ||
                fields[1].table.showDeleteButton
                  ? fields[1].table.headers.length - 1
                  : fields[1].table.headers.length
              "
            />
            <col
              v-if="
                fields[1].table.showEditButton ||
                fields[1].table.showDeleteButton
              "
              width="80px"
            />
          </template>
          <template v-slot:actions="{ index }">
            <div class="editable-list__datatable-buttons">
              <sm-tooltip
                position="bottom"
                text="Удалить"
                class="editable-list__datatable-tooltip"
              >
                <button
                  colorType="danger"
                  class="editable-list__datatable-button"
                  @click="openDeleteModal(fields[1].table.key, index)"
                >
                  <sm-icon
                    name="trash"
                    class="editable-list__datatable-button-icon"
                  />
                </button>
              </sm-tooltip>
              <sm-tooltip
                position="bottom"
                text="Изменить"
                class="editable-list__datatable-tooltip"
              >
                <button
                  colorType="success"
                  class="editable-list__datatable-button"
                  @click="openModal(fields[1].table.key, index)"
                >
                  <sm-icon
                    name="pencil-alt"
                    class="editable-list__datatable-button-icon"
                  />
                </button>
              </sm-tooltip>
            </div>
          </template>
        </sm-datatable>
        <sm-form-modal
          class="telemarketing-item-modal"
          v-model="answersModalForm"
          :key="modalKey"
          :fields="fields[1].table.modalFields"
          :show="showAnswersModalForm"
          :modal-title="fields[1].table.caption"
          @select="onSelectFromModal"
          @close="onCancelSaveModalForm"
          @save="onSaveModalForm(fields[1].table.key)"
          @cancel="onCancelSaveModalForm"
        />
      </div>
    </template>
  </sm-editable-item>
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import SmTextarea from '@/components/common/forms/SmTextarea.vue';
import SmInput from '@/components/common/forms/SmInput.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import SmFormModal from '@/components/common/modals/SmFormModal.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';
import SmTooltip from '@/components/common/SmTooltip.vue';

export default {
  name: 'TelemarketingTaskCreate',

  components: {
    SmEditableItem,
    SmTextarea,
    SmInput,
    SmIcon,
    SmFormModal,
    SmDatatable,
    SmTooltip,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'TelemarketingTasks',
      pageHeader: 'Создание задания по телемаркетингу',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Задания по телемаркетингу',
          route: { name: 'TelemarketingTasks' },
        },
        {
          text: 'Создание задания по телемаркетингу',
        },
      ],
      initialForm: {
        accountId: null,
        tags: '',
        comment: '',
        callResult: null,
        // organization
        organizationName: '',
        organizationAddress: '',
        organizationPhone: '',
        organizationMail: '',
        organizationUrl: '',
        organizationInn: '',
        chief: '',
        // contact
        contactFio: '',
        contactPhoneMobile: '',
        contactPhoneWork: '',
        contactMail: '',
        contactPosition: '',

        productId: null,
        scriptId: null,
        contacts: [],
        answers: [],
        questionsComments: [],
      },
      form: null,
      modalKey: 0,
      initialContactForm: {
        fio: null,
        phone: null,
        mail: null,
        position: null,
      },
      answersModalForm: {
        questionId: null,
        answerId: null,
      },
      showAnswersModalForm: false,
      modalAnswersTitle: 'Ответы на вопросы скрипта',
      answersModalRenderKey: 0,
      editItemIndex: null,
      telemarketingScriptQuestions: [],
      telemarketingScriptQuestionsAnswers: [],
      currentTelemarketingScriptQuestions: [],
      telemarketingAnswersList: [],
      formAnswersTable: [],
      isAnswersModalFieldMiltiselect: false,
      modalTelemarketingAnswers: [],
      currentScriptText: null,
    };
  },

  computed: {
    ...mapState({
      employeeAccounts: (state) => state.common.employeeAccounts,
      callResults: (state) => state.common.callResults,
      telemarketingTasksRejectionReasons: (state) =>
        state.common.telemarketingTasksRejectionReasons,
      telemarketingProducts: (state) => state.common.telemarketingProducts,
      telemarketingScriptsForProduct: (state) =>
        state.common.telemarketingScriptsForProduct,
      telemarketingAnswers: (state) => state.common.telemarketingAnswers,
    }),

    scriptText() {
      const { data } = this.telemarketingScriptsForProduct;
      const { scriptId } = this.form;
      const currentScript = data.find((item) => item.id === scriptId);
      if (!currentScript) {
        return null;
      }
      return currentScript.scriptText;
    },

    formCommentsTable() {
      if (
        this.form.questionsComments &&
        this.form.questionsComments.length &&
        this.telemarketingScriptQuestions.length
      ) {
        return this.form.questionsComments.map((item) => {
          let currentQuestion = this.telemarketingScriptQuestions.find(
            (question) => question.value === item.questionId
          );

          return {
            ...item,
            question: currentQuestion?.name,
          };
        });
      }
      return [];
    },

    notCreatedTelemarketingScriptQuestions() {
      const telemarketingScriptQuestions = this.telemarketingScriptQuestions;
      const { answers } = this.form;

      const questions = telemarketingScriptQuestions.filter(({ value }) => {
        const createdAnswer = answers.find(
          ({ questionId }) => questionId === value
        );

        if (createdAnswer) return false;

        return true;
      });

      return questions;
    },

    fields() {
      return [
        {
          tab: 'Основные',
          groups: [
            {
              form: [
                {
                  type: 'select',
                  key: 'accountId',
                  label: 'Аккаунт',
                  list: this.employeeAccounts.data,
                },
              ],
            },
            {
              groupTitle: 'Задание',
              form: [
                {
                  type: 'select',
                  key: 'productId',
                  label: 'Продукт телемаркетинга',
                  list: this.telemarketingProducts.data,
                },
                {
                  type: 'select',
                  key: 'scriptId',
                  label: 'Скрипт телемаркетинга',
                  list: this.telemarketingScriptsForProduct.data?.map(
                    (item) => ({
                      ...item,
                      name: item.script,
                    })
                  ),
                },
                {
                  type: 'textarea',
                  key: 'scriptText',
                  label: 'Текст скрипта',
                  disabled: true,
                },
              ],
            },
            // organization
            {
              groupTitle: 'Организация',
              form: [
                {
                  type: 'textarea',
                  key: 'organizationName',
                  label: 'Название организации',
                  attributes: {
                    rows: 3,
                  },
                },
                {
                  type: 'textarea',
                  key: 'organizationAddress',
                  label: 'Адрес организации',
                  attributes: {
                    rows: 3,
                  },
                },
                {
                  type: 'text',
                  key: 'organizationPhone',
                  label: 'Телефон организации',
                },
                {
                  type: 'text',
                  key: 'organizationMail',
                  label: 'Email организации',
                },
                {
                  type: 'text',
                  key: 'organizationUrl',
                  label: 'Веб-сайт организации',
                },
                {
                  type: 'text',
                  key: 'organizationInn',
                  label: 'ИНН организации',
                },
                {
                  type: 'text',
                  key: 'chief',
                  label: 'ФИО директора',
                },
              ],
            },
            {
              groupTitle: 'Результат обзвона',
              form: [
                {
                  type: 'select',
                  key: 'callResult',
                  label: 'Результат обзвона',
                  list: this.callResults.data,
                }, // CallResults
                { type: 'text', key: 'tags', label: 'Теги' },
                { type: 'textarea', key: 'comment', label: 'Комментарий' },
                {
                  type: 'select',
                  key: 'rejectionReasonId',
                  label: 'Причина отказа',
                  list: this.telemarketingTasksRejectionReasons.data,
                },
              ],
            },

            // contact
            {
              groupTitle: 'Контактные лица',
              form: [
                {
                  type: 'textarea',
                  key: 'contactFio',
                  label: 'ФИО контактного лица',
                  attributes: {
                    rows: 3,
                  },
                },
                {
                  type: 'text',
                  key: 'contactPhoneMobile',
                  label: 'Мобильный телефон контактного лица',
                },
                {
                  type: 'text',
                  key: 'contactPhoneWork',
                  label: 'Рабочий телефон контактного лица',
                },
                {
                  type: 'text',
                  key: 'contactMail',
                  label: 'Email контактного лица',
                },
                {
                  type: 'text',
                  key: 'contactPosition',
                  label: 'Должность контактного лица',
                },
                {
                  type: 'textarea',
                  key: 'contacts',
                  label: 'Контакты',
                },
              ],
            },
          ],
        },
        {
          tab: 'Ответы на вопросы скрипта',
          table: {
            caption: 'Ответы на вопросы скрипта',
            headers: [
              {
                text: 'Вопрос',
                alias: 'question',
              },
              {
                text: 'Комментарий',
                alias: 'comment',
              },
              {
                text: 'Ответ',
                alias: 'answer',
              },
              { alias: 'actions' },
            ],
            items: this.form.answers,
            key: 'answers',
            modalFields: [
              {
                type: 'select',
                key: 'questionId',
                label: 'Вопрос',
                list: this.currentTelemarketingScriptQuestions,
              },
              {
                type: 'select',
                key: 'answerId',
                label: 'Ответ',
                list: this.modalTelemarketingAnswers,
                attributes: {
                  multiselect: this.isAnswersModalFieldMiltiselect,
                },
              },
              {
                type: 'textarea',
                key: 'comment',
                label: 'Комментарий',
              },
            ],
            showEditButton: true,
          },
        },
        {
          tab: 'Комментарии к вопросам',
          table: {
            caption: 'Комментарии к вопросам',
            headers: [
              {
                text: 'Вопрос',
                alias: 'question',
              },
              {
                text: 'Комментарий',
                alias: 'Comment',
              },
              { alias: 'actions' },
            ],
            items: this.formCommentsTable,
            key: 'questionsComments',
            modalFields: [
              {
                type: 'select',
                key: 'questionId',
                label: 'Вопрос',
                list: this.telemarketingScriptQuestions,
              },
              {
                type: 'textarea',
                key: 'Comment',
                label: 'Комментарий',
              },
            ],
            showEditButton: true,
            showCreateButton: true,
            showDeleteButton: true,
          },
        },
      ];

      // if (this.showCheckList) {
      //   groups.push({
      //     groupTitle: 'Чек-лист',
      //     form: [
      //       {
      //         type: 'textarea',
      //         key: 'checkList',
      //         label: 'Чек-лист',
      //       },
      //     ],
      //   });
      // }

      // return [
      //   {
      //     groups,
      //   },
      // ];
    },
  },

  watch: {
    'form.productId': {
      handler(newVal) {
        if (newVal) {
          this.getCommonList({
            name: 'TelemarketingScriptsForProduct',
            params: { productid: this.form.productId },
          });
        }
      },
    },

    'answersModalForm.questionId': {
      handler(newVal) {
        if (newVal) {
          this.getCommonList({
            name: 'TelemarketingAnswers',
            params: { questionId: this.answersModalForm.questionId },
          }).then(() => {
            const currentsQuestion = this.telemarketingScriptQuestions.find(
              (item) => item.value === this.answersModalForm.questionId
            );

            this.isAnswersModalFieldMiltiselect =
              currentsQuestion.multipleAnswers;

            this.modalTelemarketingAnswers =
              this.telemarketingAnswers.data || [];
          });
        }
      },
      immediate: true,
      deep: true,
    },

    'answersModalForm.answerId': {
      handler(value) {
        if (!value) {
          this.$set(this.answersModalForm, 'answer', '');
          return;
        }

        if (Array.isArray(value)) {
          const findedAnswers = [];

          value.forEach((answerId) => {
            const findedAnswer =
              this.telemarketingAnswers.data.find(
                (item) => item.value === answerId
              )?.name || '';

            if (findedAnswer) findedAnswers.push(findedAnswer);
          });

          this.$set(this.answersModalForm, 'answer', findedAnswers.join('; '));

          return;
        }

        const findedAnswer =
          this.telemarketingAnswers.data.find((item) => item.value === value)
            ?.name || '';

        this.$set(this.answersModalForm, 'answer', findedAnswer);
      },
      immediate: true,
      deep: true,
    },

    'form.scriptId': {
      handler(newVal) {
        if (newVal) {
          this.getTelemarketingScriptQuestions(newVal)
            .then((res) => {
              this.telemarketingScriptQuestions = res.map(
                ({ question, questionId, answers, multipleAnswers }) => {
                  return {
                    name: question,
                    value: questionId,
                    answers,
                    multipleAnswers,
                  };
                }
              );
            })
            .then(() => {
              this.$set(this.form, 'answers', this.getCurrentAnswersTable());
            });

          this.currentScriptText = this.scriptText;
        }
      },
    },

    'form.answers': {
      handler(newVal) {
        if (newVal && newVal.length) {
          newVal.forEach((item) => {
            this.getCommonList({
              name: 'TelemarketingAnswers',
              params: { questionId: item.questionId },
            }).then((res) => {
              this.telemarketingAnswersList.push(...res);
            });
          });
        }
      },
      immediate: true,
    },

    telemarketingAnswersList: {
      handler(newVal) {
        if (newVal && newVal.length) {
          this.formAnswersTable = this.createFormAnswersTable();
        }
      },
      immediate: true,
    },
  },

  created() {
    const employeeAccounts = this.getCommonList({ name: 'EmployeeAccounts' });
    const callResults = this.getCommonList({ name: 'CallResults' });
    const telemarketingTasksRejectionReasons = this.getCommonList({
      name: 'TelemarketingTasksRejectionReasons',
    });
    const telemarketingProducts = this.getCommonList({
      name: 'TelemarketingProducts',
    });

    this.isLoadingPage = true;
    Promise.all([
      employeeAccounts,
      callResults,
      telemarketingTasksRejectionReasons,
      telemarketingProducts,
    ]).then(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
      getTelemarketingScriptQuestions:
        'telemarketing/getTelemarketingScriptQuestions',
    }),

    onAddContact() {
      this.form.contacts.push(this.lodash.cloneDeep(this.initialContactForm));
    },

    openDeleteContactModal(index) {
      this.$root
        .$confirmModal({
          message: 'Удалить контакт?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            this.form.contacts.splice(index, 1);
          }
        });
    },

    createFormAnswersTable() {
      if (
        this.form.answers &&
        this.form.answers.length &&
        this.telemarketingScriptQuestions.length &&
        this.telemarketingAnswersList.length
      ) {
        return this.form.answers.map((item) => {
          let currentQuestion = this.telemarketingScriptQuestions.find(
            (question) => question.value === item.questionId
          );

          const currentAnswer = this.telemarketingAnswersList.find(
            (answer) => answer.id === item.answerId
          )?.name;

          return {
            question: currentQuestion?.name,
            answer: currentAnswer,
          };
        });
      }
      return [];
    },

    openDeleteModal(tableKey, index) {
      this.$root
        .$confirmModal({
          message: 'Удалить запись?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            this.form[tableKey].splice(index, 1);
          }
        });
    },

    openModal(tableKey, index) {
      if (index !== null && index !== undefined) {
        this.editItemIndex = index;
        this.answersModalForm = { ...this.form[tableKey][index] };

        const { questionId } = this.answersModalForm;
        const findedQuestion = this.telemarketingScriptQuestions.find(
          ({ value }) => value === questionId
        );

        if (findedQuestion) {
          this.currentTelemarketingScriptQuestions = [
            findedQuestion,
            ...this.notCreatedTelemarketingScriptQuestions,
          ];
        } else {
          this.currentTelemarketingScriptQuestions =
            this.notCreatedTelemarketingScriptQuestions;
        }
      } else {
        this.currentTelemarketingScriptQuestions =
          this.notCreatedTelemarketingScriptQuestions;
        let currentTable = this.fields.find(
          (item) => item.table && item.table.key === tableKey
        );
        for (let i in currentTable.table.modalFields) {
          if (currentTable.table.modalFields[i].type === 'checkbox') {
            this.answersModalForm = {
              ...this.answersModalForm,
              [currentTable.table.modalFields[i].key]: false,
            };
          } else {
            this.answersModalForm = {
              ...this.answersModalForm,
              [currentTable.table.modalFields[i].key]: '',
            };
          }
        }
      }
      this.showAnswersModalForm = true;
    },

    onSelectFromModal({ key }) {
      if (key === 'questionId') {
        this.answersModalForm.answerId = null;
      }
    },

    onSaveModalForm(tableKey) {
      this.showAnswersModalForm = false;
      for (let i in this.answersModalForm) {
        if (this.answersModalForm[i] === '') {
          delete this.answersModalForm[i];
        }
      }
      if (this.editItemIndex !== null) {
        const { questionId } = this.answersModalForm;

        let item = this.answersModalForm;

        if (questionId) {
          const findedQuestionName = this.telemarketingScriptQuestions.find(
            ({ value }) => value === questionId
          )?.name;

          item = { ...item, question: findedQuestionName };
        }

        this.form[tableKey].splice(this.editItemIndex, 1, {
          ...item,
        });
      } else {
        const { questionId } = this.answersModalForm;

        let item = this.answersModalForm;

        if (questionId) {
          const findedQuestionName = this.telemarketingScriptQuestions.find(
            ({ value }) => value === questionId
          )?.name;

          item = { ...item, question: findedQuestionName };
        }

        this.form[tableKey].push(item);
      }
      this.clearModalForm();
    },

    onCancelSaveModalForm() {
      this.clearModalForm();
    },

    clearModalForm() {
      this.editItemIndex = null;
      this.showAnswersModalForm = false;
      this.answersModalForm = {};
      this.modalTelemarketingAnswers = [];
      this.modalKey += 1;
    },

    getCurrentAnswersTable() {
      const answers = this.lodash.cloneDeep(this.form.answers) || [];
      const telemarketingScriptQuestions =
        this.telemarketingScriptQuestions.map(({ name, value }) => ({
          question: name,
          questionId: value,
          comment: '',
        })) || [];
      const currentAnswers = [...telemarketingScriptQuestions];

      for (let i = 0; i < answers.length; i++) {
        const currentAnswer = answers[i];
        const currentQuestionId = currentAnswer.questionId;
        const findedAnswerIndex = currentAnswers.findIndex(
          ({ questionId }) => questionId === currentQuestionId
        );

        if (findedAnswerIndex !== -1) {
          const findedAnswer = currentAnswers[findedAnswerIndex];
          let payload = {
            multipleAnswers: findedAnswer.multipleAnswers,
          };

          if (
            findedAnswer?.multipleAnswers &&
            Array.isArray(findedAnswer.answerId)
          ) {
            const answersArray = [findedAnswer.answer];
            payload = { ...findedAnswer };
            payload.id = [...findedAnswer.id, currentAnswer.id];

            if (currentAnswer.answerId) {
              payload.answerId.push(currentAnswer.answerId);
              answersArray.push(currentAnswer.answer);
            }

            payload.answer = answersArray.join('; ');
          } else if (findedAnswer?.multipleAnswers && currentAnswer?.answerId) {
            payload.answerId = [];
            payload.answer = '';
            payload.id = [currentAnswer.id];

            if (currentAnswer.answerId) {
              payload.answerId.push(currentAnswer.answerId);
              payload.answer = currentAnswer.answer;
            }
          }

          currentAnswers.splice(findedAnswerIndex, 1, {
            ...currentAnswer,
            ...payload,
          });
        }
      }

      if (this.form.answers?.length) {
        return currentAnswers.filter((answer) => 'id' in answer);
      }

      return currentAnswers;
    },

    getServerValidAnswers() {
      const answers = this.form.answers || [];
      const result = [];

      for (let i = 0; i < answers.length; i++) {
        const currentAnswer = answers[i];

        if (
          Array.isArray(currentAnswer?.answerId) &&
          currentAnswer.answerId.length
        ) {
          currentAnswer.answerId.forEach((answerId) => {
            const answerObject = { ...currentAnswer, answerId };

            delete answerObject.id;

            result.push(answerObject);
          });
        } else if (Array.isArray(currentAnswer?.answerId)) {
          const answerObject = { ...currentAnswer, answerId: null };

          delete answerObject.id;

          result.push(answerObject);
        } else if (Array.isArray(currentAnswer?.id)) {
          const answerObject = { ...currentAnswer };

          delete answerObject.id;

          result.push(answerObject);
        } else result.push(currentAnswer);
      }

      return result;
    },

    onSaveForm() {
      const formCopy = this.lodash.cloneDeep(this.form);

      this.$set(this.form, 'answers', this.getServerValidAnswers());
      this.onSave('TelemarketingTasks');
      this.form = formCopy;
    },
  },
};
</script>

<style lang="scss">
.telemarketing-form__row {
  display: flex;
}

.telemarketing-form__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  columns: 2;
  column-gap: 15px;
}

.telemarketing-form__column {
  display: flex;
  flex: 0 0 49%;
  margin-bottom: 15px;
}

.telemarketing-form__description {
  width: 250px;
  align-self: center;
}

.telemarketing-form__delete-button {
  margin: 0;

  color: var(--gray);

  border: none;
  background: transparent;

  cursor: pointer;
}

.telemarketing-form__delete-button:hover {
  color: var(--red);

  transition: color 0.3s;
}

.telemarketing-form__add-button {
  display: flex;
  align-items: center;
  margin: 0;

  line-height: 1;
  font-weight: 500;
  color: var(--gray);

  cursor: pointer;

  border: none;
  background: transparent;

  transition: color 0.3s;
}

.editable-list__datatable-buttons {
  display: flex;
}
</style>
